<template>
    <PageLayout>
        <template v-slot:default>
            <div class="d-flex justify-space-between mb-6">
                <div class="d-flex align-center">
                    <h1>{{ $t('sale.dashboard') }}</h1>
                </div>
            </div>
            <v-row>
                <v-col cols="12">
                    <v-card class="w-100 d-flex flex-column" style="height: 100%">
                        <v-card-title>
                            {{ $t('sale.process') }}
                        </v-card-title>
                        <v-card-text class="process__wrap flex-grow-1">
                            <table class="process">
                                <tr>
                                    <td>
                                        <RouterLink :to="{name: 'sale.buyer_order'}" class="process__step">
                                            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M36 26C42.6274 26 48 20.6274 48 14C48 7.37258 42.6274 2 36 2C29.3726 2 24 7.37258 24 14C24 20.6274 29.3726 26 36 26Z" stroke="#898989"
                                                      stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M40 14.4H36V8" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M12 23V29" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M16 48L17 36H22V29C22 23.4772 17.5228 19 12 19C6.47715 19 2 23.4772 2 29V36H7L8 48H16Z" stroke="#898989" stroke-width="2.5"
                                                      stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M5.96799 5.44601C8.15827 7.71768 11.1784 9.00067 14.334 9.00001C15.9157 9.00038 17.4808 8.6785 18.934 8.05401" stroke="#898989" stroke-width="2.5" stroke-linecap="round"
                                                      stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 16C15.866 16 19 12.866 19 9C19 5.13401 15.866 2 12 2C8.13401 2 5 5.13401 5 9C5 12.866 8.13401 16 12 16Z" stroke="#898989"
                                                      stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <span>Заявка от покупателя</span>
                                        </RouterLink>
                                    </td>
                                    <td></td>
                                    <td>
                                        <RouterLink :to="{ name: 'accounting_invoice', query: { filter: {'payment_direction_id': { 'eq': '2' } } } }" class="process__step">
                                            <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M30 19.982V11.982H8V37.98L18 37.982" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path
                                                    d="M36 17.982V7.98199C36 6.87742 35.1046 5.98199 34 5.98199H25.104C24.0439 3.55828 21.6494 1.99213 19.004 1.99213C16.3586 1.99213 13.9641 3.55828 12.904 5.98199H4C2.89543 5.98199 2 6.87742 2 7.98199V41.982C2 43.0866 2.89543 43.982 4 43.982H20"
                                                    stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M14 17.982H24" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M14 23.982H24" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M14 29.982H20" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path
                                                    d="M39 29.982H34.934C33.6468 29.9805 32.5398 30.893 32.2956 32.1569C32.0514 33.4207 32.7389 34.6799 33.934 35.158L38.062 36.808C39.2543 37.2877 39.9392 38.5454 39.6954 39.8073C39.4515 41.0692 38.3472 41.9811 37.062 41.982H33"
                                                    stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M36 43.982V41.982" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M36 29.982V27.982" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                      d="M36 47.982C42.6274 47.982 48 42.6094 48 35.982C48 29.3546 42.6274 23.982 36 23.982C29.3726 23.982 24 29.3546 24 35.982C24 42.6094 29.3726 47.982 36 47.982Z" stroke="#898989"
                                                      stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M49 10L41 10" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M45 6L49 10L45 14" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <span>Счет на оплату</span>
                                        </RouterLink>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="arrow down"></div>
                                    </td>
                                    <td></td>
                                    <td>
                                        <div class="arrow up"></div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <RouterLink :to="{name: 'sale.buyer_order'}" class="process__step">
                                            <svg width="48" height="50" viewBox="0 0 48 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M36.036 36.124C36.6571 35.4 36.999 34.4779 37 33.524V12H11V42H29.16C30.3285 41.9995 31.4384 41.488 32.198 40.6L36.036 36.124Z" stroke="#898989"
                                                      stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                      d="M41 5.99999H30.104C29.0439 3.57629 26.6494 2.01013 24.004 2.01013C21.3586 2.01013 18.9641 3.57629 17.904 5.99999H7C5.89543 5.99999 5 6.89542 5 7.99999V46C5 47.1046 5.89543 48 7 48H41C42.1046 48 43 47.1046 43 46V7.99999C43 6.89542 42.1046 5.99999 41 5.99999Z"
                                                      troke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M31 18H17" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M31 26H17" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M26 34H17" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <span>Заказ покупателя</span>
                                        </RouterLink>
                                    </td>
                                    <td>
                                        <div class="arrow left"></div>
                                    </td>
                                    <td>
                                        <RouterLink :to="{name: 'realization'}" class="process__step">
                                            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17 42C18.6569 42 20 40.6569 20 39C20 37.3431 18.6569 36 17 36C15.3431 36 14 37.3431 14 39C14 40.6569 15.3431 42 17 42Z" stroke="#898989"
                                                      stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M2 2H7.416C8.34381 2.0004 9.14953 2.63884 9.362 3.542L17 36H25" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M44.968 23.094L47.122 16.632C47.325 16.0224 47.2228 15.3523 46.8473 14.831C46.4717 14.3097 45.8685 14.0005 45.226 14H11.824" stroke="#898989" stroke-width="2.5" stroke-linecap="round"
                                                      stroke-linejoin="round"/>
                                                <path d="M15.588 30H24.95" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M28 38C28 43.5228 32.4772 48 38 48C43.5228 48 48 43.5228 48 38C48 32.4772 43.5228 28 38 28C32.4772 28 28 32.4772 28 38Z" stroke="#898989"
                                                      stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M42 38L34 38" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M38 34L42 38L38 42" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <span>{{ $t('realization.menu') }}</span>
                                        </RouterLink>
                                    </td>
                                    <td>
                                        <div class="arrow"></div>
                                    </td>
                                    <td>
                                        <RouterLink :to="{name: 'account_payment', query: {filter:{payment_direction_id:{eq:'2'}}}}" class="process__step">
                                            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M39 30H34.934C33.6488 30.0009 32.5445 30.9128 32.3006 32.1747C32.0568 33.4366 32.7417 34.6942 33.934 35.174L38.062 36.826C39.2544 37.3058 39.9393 38.5634 39.6954 39.8253C39.4515 41.0872 38.3472 41.9991 37.062 42H33"
                                                    stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M36 44V42.002" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M36 48C42.6274 48 48 42.6274 48 36C48 29.3726 42.6274 24 36 24C29.3726 24 24 29.3726 24 36C24 42.6274 29.3726 48 36 48Z" stroke="#898989"
                                                      stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M36 30.002V28" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M5.968 5.44601C8.15829 7.71768 11.1784 9.00067 14.334 9.00001C15.9157 9.00038 17.4808 8.6785 18.934 8.05401" stroke="#898989" stroke-width="2.5" stroke-linecap="round"
                                                      stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 16C15.866 16 19 12.866 19 9C19 5.13401 15.866 2 12 2C8.13401 2 5 5.13401 5 9C5 12.866 8.13401 16 12 16Z" stroke="#898989"
                                                      stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M21.026 24.69C19.0141 20.4716 14.3487 18.2094 9.79066 19.2419C5.23258 20.2745 1.99757 24.3264 2 29V36H7L8 48H16L17 36H19.964" stroke="#898989" stroke-width="2.5"
                                                      stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M39 11L31 11" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M38 7L42 11L38 15" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <span>Оплата</span>
                                        </RouterLink>
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <div class="arrow down"></div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <RouterLink :to="{name: 'sale.return'}" class="process__step">
                                            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M38 42L34 38L38 34" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M34 38L42 38" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M15.5879 30H24.9499" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M44.968 23.094L47.122 16.632C47.325 16.0224 47.2228 15.3523 46.8472 14.831C46.4717 14.3097 45.8685 14.0005 45.226 14H11.824" stroke="#898989" stroke-width="2.5" stroke-linecap="round"
                                                      stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M28 38C28 43.5228 32.4772 48 38 48C43.5228 48 48 43.5228 48 38C48 32.4772 43.5228 28 38 28C32.4772 28 28 32.4772 28 38Z" stroke="#898989"
                                                      stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M2 2H7.416C8.34381 2.0004 9.14953 2.63884 9.362 3.542L17 36H25" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17 42C18.6569 42 20 40.6569 20 39C20 37.3431 18.6569 36 17 36C15.3431 36 14 37.3431 14 39C14 40.6569 15.3431 42 17 42Z" stroke="#898989"
                                                      stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <span>Возврат товара</span>
                                        </RouterLink>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td><RouterLink :to="{name: 'sale.report'}" class="process__step">
                                        <svg width="50" height="48" viewBox="0 0 50 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11 44.008H39" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M24 40.008V44.008" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M2 32.008H48" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M18 28.008C23.5228 28.008 28 23.5309 28 18.008C28 12.4852 23.5228 8.008 18 8.008C12.4772 8.008 8 12.4852 8 18.008C8 23.5309 12.4772 28.008 18 28.008Z" stroke="#898989"
                                                  stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M23.654 9.76L18 18.008L24.41 25.68" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M18 18.008H8" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M34 10.008H42" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M34 16.008H42" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M34 22.008H42" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M2 5.508C2 4.67958 2.67157 4.008 3.5 4.008H46.5C47.3284 4.008 48 4.67958 48 5.508V38.508C48 39.3364 47.3284 40.008 46.5 40.008H3.5C2.67157 40.008 2 39.3364 2 38.508V5.508Z"
                                                  stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        <span>Отчеты</span>
                                    </RouterLink></td>
                                </tr>
                            </table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </template>
        <template v-slot:side>
            <Side />
        </template>
    </PageLayout>
</template>
<script>
import PageLayout from "@/components/Leentech/PageLayout.vue";
import NavMixin from "@/plugins/mixins/Nav";
import Side from "@/components/Warehouse/Side.vue";

export default {
    name: "Sale",

    components: {
        Side,
        PageLayout
    },

    mixins: [
        NavMixin
    ],

    data() {
        return {}
    },

    computed: {},

    mounted() {
    },

    methods: {}
}
</script>
